<template>
  <div class="home">
    <v-layout row>
            <!--<v-col xl=1 md=2>
            <v-img 
                :src="require('../../src/assets/logo.svg')"
                max-width=42 
                contain 
                alt="Comori-OD"/>
            </v-col>
            <v-col align="center">
                <h1 class="heading">Comorile Oastei Domnului</h1>
            </v-col>-->
    </v-layout>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ComoriHome',
  components: {
  }
}
</script>
